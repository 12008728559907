.sidebar {
  width: 250px;
}

.icon {
  transform: rotate(180deg);
}

.rotate {
  transform: rotate(0deg);
}

p {
  transition: transform .3s ease;
}

.dropdown-toggle::after {
  display: none !important;
}

.col {
  padding-top: 12px;
  padding-bottom: 12px;
}