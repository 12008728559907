/* SearchBar.css */
.navbar-brand span {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-link i {
  font-size: 1.25rem;
}

.p-3 {
  background-color: #fff;
}

.logo {
  max-width: 300px;
}

.header {
  padding: 2em;
}

.input-group {
  max-width: 90%;
  display: flex;
  justify-content: center;
}

.form-control {
  border: 2px solid #ea1b25 !important;
  max-width: 90% !important;
  border-radius: 20px 2px 2px 20px !important;
  height: 41px !important;
}

.input-group-append {
  background-color: #ea1b25;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  align-items: center;
  color: white;
  padding: 0px 15px 0px 15px;
}

.count-item {
  position: relative;
  background-color: #ea1b25;
  color: white;
  border-radius: 50%;
  padding: 5px;
  height: 20px;
  display: flex;
  align-items: center ;
  justify-content: center;
  aspect-ratio: 1/1;
  right: 20px;
  top: 10px;
  font-size: 10px;
}

.color-black .align-top{
  position: relative;
  left: 10px;
}

.color-black .count-item {
  left: -2px;
  background-color: black;
}
