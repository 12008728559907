/* styles.css */

.brand-container {
  text-align: center;
  padding: 20px;
}

.brand-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  color: #333;
}

.brand-logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.brand-logo {
  margin: 10px;
  width: 130px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.brand-logo img {
  max-width: 80%;
  max-height: 80%;
}

@media (max-width: 600px) {
  .brand-logos {
      flex-direction: column;
      align-items: center;
  }
}
