/* FloatingBox.css */
@keyframes floating {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-5px);
  }
}

.floating-box {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 20px auto;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}

.floating-box:hover {
  animation: floating 2s ease-in-out infinite;
}
