.carousel .carousel-slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 600px;
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
}

.carousel .control-arrow:hover {
  background: rgba(0, 0, 0, 0.4);
}
